var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('PLoader'):_vm._l((_vm.products),function(product,index){return _c('div',{key:index,staticClass:"mt-1 mb-2 py-2 px-3 border rounded-md flex flex-col md:flex-row items-center w-full",class:{
        'bg-gradient-to-b from-gray-50 to-gray-200  border-gray-300':
          product.quantity === 0,
        'bg-green-100 border-green-500': product.quantity,
      }},[_c('img',{attrs:{"src":`${_vm.assetsBaseUrl}/media.ashx?${product.image}&template=product-image-69`}}),_c('div',{staticClass:"md:ml-4 text-center md:text-left"},[(_vm.isHE && product.qualityId === 'SORT')?[_c('div',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(product.qualityName)+" ")])]:[_c('div',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(product.productName)+" ")]),(!_vm.isExternal && (_vm.isGL && product.isPooling) === false)?_c('div',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(product.qualityName)+" "+_vm._s(product.treatmentIsDefault ? "" : product.treatmentName)+" ")]):_vm._e()]],2),_c('div',{staticClass:"md:flex-1 flex flex-col md:flex-row items-center md:justify-end"},[(product.qtyStockType === 'Receipt')?_c('div',{staticClass:"ml-4 text-center"},[_c('div',{staticClass:"font-medium"},[_c('PTranslation',{attrs:{"k":"Common.General.PalletReceipt"}})],1),_c('div',{staticClass:"text-sm"},[_c('PTranslation',{attrs:{"k":"Common.General.qtyStock"}}),_vm._v(": "+_vm._s(product.qtyStock)+" ")],1)]):(_vm.showBalances && product.isCommission)?_c('div',{staticClass:"ml-4 text-center"},[_c('div',{staticClass:"font-medium"},[_c('PTranslation',{attrs:{"k":"Common.General.Commission"}})],1),(product.qtyStock !== null)?_c('div',{staticClass:"text-sm",class:{
              'text-green-500':
                (_vm.location.showInternalStock && product.qtyStock < 0) ||
                (!_vm.location.showInternalStock && product.qtyStock > 0),
              'text-red-500':
                (_vm.location.showInternalStock && product.qtyStock > 0) ||
                (!_vm.location.showInternalStock && product.qtyStock < 0),
            }},[(
                (_vm.location.showInternalStock && product.qtyStock < 0) ||
                (!_vm.location.showInternalStock && product.qtyStock > 0)
              )?[_c('span',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(product.qtyStock * (_vm.location.showInternalStock ? -1 : 1))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$tk("Common.General.Credited").toLowerCase())+" ")]),(
                  product.noNegComm && product.qtyMax < product.qtyMaxOrder
                )?_c('span',{staticClass:"text-red-500"},[_vm._v(" ("+_vm._s(_vm.$tk("Common.General.Max").toLowerCase())+") ")]):_vm._e()]:(
                (_vm.location.showInternalStock && product.qtyStock > 0) ||
                (!_vm.location.showInternalStock && product.qtyStock < 0)
              )?[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(product.qtyStock * (_vm.location.showInternalStock ? 1 : -1)))]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$tk('Common.General.Owes').toLowerCase())}})]:[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$tk('Common.General.Balance'))}}),_vm._v(": "),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(product.qtyStock))])]],2):_vm._e()]):_vm._e(),(!_vm.hidePricesSveCatC && _vm.showPrices && product.price > 0)?_c('div',{staticClass:"flex items-baseline text-lg"},[_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(product.currencyId))]),_c('span',{staticClass:"ml-1 font-semibold"},[_vm._v(_vm._s(_vm.numberFormat(product.price, 2)))])]):_vm._e(),(_vm.hidePricesSveCatC)?_c('div',[_c('div',{staticClass:"inline-flex items-center"},[_c('span',{staticClass:"text-xs"},[_vm._v("Prisuppgift")]),_c('PTooltip',{staticClass:"ml-1 text-xs",attrs:{"content":'Ta kontakt med order@smartretur.se'}})],1)]):_vm._e(),_c('div',{staticClass:"md:w-20 ml-4 text-center leading-none",class:{ invisible: product.isBlocked }},[(!product.qtyFree && product.qtyPick > 1)?[_vm._v(" ±"+_vm._s(product.qtyPick)+" ")]:_vm._e()],2),(!product.isBlocked || _vm.loginUser.isSmartRetur)?[_c('div',{staticClass:"relative mt-2 md:mt-0"},[(product.quantityExtra > 0)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.$t('Common.General.OfWhichUnderOtherProducts', {
                  count: product.quantityExtra,
                  name: product.productName.toLowerCase(),
                }),
                delay: {
                  show: 100,
                  hide: 100,
                },
              }),expression:"{\n                content: $t('Common.General.OfWhichUnderOtherProducts', {\n                  count: product.quantityExtra,\n                  name: product.productName.toLowerCase(),\n                }),\n                delay: {\n                  show: 100,\n                  hide: 100,\n                },\n              }"}],staticClass:"mt-1 text-red-500 text-xs absolute z-10 top-2 left-2"},[_c('FontAwesomeIcon',{attrs:{"icon":['fad', 'pallet-alt']}}),_vm._v(" "+_vm._s(product.quantityExtra)+" ")],1):_vm._e(),(product.isBlocked)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.$t('OrderDialog.NotAvailableButAsSRYouCanOrder'),
                delay: {
                  show: 100,
                  hide: 100,
                },
              }),expression:"{\n                content: $t('OrderDialog.NotAvailableButAsSRYouCanOrder'),\n                delay: {\n                  show: 100,\n                  hide: 100,\n                },\n              }"}],staticClass:"mt-1 text-red-500 text-xs absolute z-10 top-2",class:{
                'left-10': product.quantityExtra > 0,
                'left-2': !product.quantityExtra,
              }},[_c('FontAwesomeIcon',{attrs:{"icon":['far', 'exclamation-triangle']}})],1):_vm._e(),_c('PNumber',{staticClass:"w-28",attrs:{"disabled":_vm.disabled,"value":product.quantity,"extra":product.quantityExtra,"min":_vm.allowNegativeVolume && product.qtyFree
                  ? -9999
                  : product.quantityExtra,"max":product.qtyMax,"warn":product.qtyWarn,"warnMessage":_vm.$t('OrderDialog.WarningHighVolume'),"step":product.qtyFree ? 1 : product.qtyPick || 1,"wait":1000},on:{"input":function($event){return _vm.setQuantity(product, $event)}}})],1),_c('div',{staticClass:"flex items-center p-2 md:p-0"},[(_vm.showFill)?_c('a',{staticClass:"text-sm underline ml-4",class:{
                'cursor-not-allowed': _vm.disabled,
                invisible: product.qtyMaxOrder === 0,
              },attrs:{"href":"","tabindex":"-1","disabled":_vm.disabled},domProps:{"innerHTML":_vm._s(_vm.$tk('Common.General.Fill'))},on:{"click":function($event){$event.preventDefault();return _vm.setQuantity100(product)}}}):_vm._e(),_c('a',{staticClass:"text-sm underline ml-4",class:{
                'cursor-not-allowed': _vm.disabled || product.quantity === 0,
                'text-red-500': product.quantity > 0,
              },attrs:{"href":"","tabindex":"-1","disabled":_vm.disabled || product.quantity === 0,"title":"Tøm dette produktet fra bil"},on:{"click":function($event){$event.preventDefault();return _vm.setQuantity0(product)}}},[_c('FontAwesomeIcon',{attrs:{"icon":['far', 'trash-alt']}})],1)])]:[_vm._t("blocked",function(){return [_c('div',{staticClass:"text-sm text-center"},[_c('PTranslation',{attrs:{"k":"Common.General.NotAvailable"}})],1)]},{"product":product})]],2)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }